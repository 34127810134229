import * as React from "react"
import { Link } from "gatsby"

import { ArrowForward } from "../Misc/SVG"

const Listings = ({ title, data }) => {

  if (data.length === 0) {
    return (
      <section id="listings">
        <h3>We do not have any open positions at the moment, but you are always welcome to send us your CV!</h3>
      </section>
    )
  }

  return (
    <section id="listings">
      <h3>{title}</h3>

      <ol className="listing">
        {data.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li
              key={post.fields.slug}
              className="listing-item"
            >
              <Link to={post.fields.slug} itemProp="url">
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <h2>{title}</h2>
                  <ArrowForward />
                </article>
              </Link>
            </li>
          )
        })}
      </ol>

    </section>
  )
}

export default Listings


