import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Hero = () => {

  const data = useStaticQuery(graphql`
    query HeroQuery {
      site {
        siteMetadata {
          hero {
            headline
            lead
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const hero = data.site.siteMetadata?.hero

  return (
    <section id="hero">
      <h1>{hero.headline}</h1>  

      <video
        class="video"
        poster="why--slideshow-cover.jpg"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src="https://prismic-io.s3.amazonaws.com/whypartnerscom/b5787834-2078-4d69-9560-8991ff7effae_why--slideshow.mp4" type="video/mp4" />
      </video>
      <p className="lead">{hero.lead}</p>
    </section>
  )
}

export default Hero
