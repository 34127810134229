import * as React from "react"
import { graphql } from "gatsby"

import '../style/main.scss';

import Layout from "../components/Layout"
import Seo from "../components/seo"

import Hero from "../components/Index/Hero"
import Listings from "../components/Index/Listings"

const JoinIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Join Us" slug="/"/>
      <Hero/>
      <Listings title={data.site.siteMetadata?.frame.listingHeader} data={posts}/>
    </Layout>
  )
}

export default JoinIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        frame {
          listingHeader
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
